define('ember-scrollable/services/scrollbar-thickness', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    thickness: Ember.computed(() => {
      const tempEl = Ember.$(`
      <div class="scrollbar-width-tester" style="width: 50px; position: absolute; left: -100px;">
        <div style="overflow: scroll;">
          <div class="scrollbar-width-tester__inner"></div>
        </div>
      </div>
    `);
      Ember.$('body').append(tempEl);
      const width = Ember.$(tempEl).width();
      const widthMinusScrollbars = Ember.$('.scrollbar-width-tester__inner', tempEl).width();
      tempEl.remove();

      return width - widthMinusScrollbars;
    })
  });
});