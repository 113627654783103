enifed("@ember/-internals/routing/lib/system/query_params", ["exports"], function (_exports) {
  "use strict";

  _exports.default = void 0;

  class QueryParams {
    constructor(values = null) {
      this.isQueryParams = true;
      this.values = values;
    }

  }

  _exports.default = QueryParams;
});