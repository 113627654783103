define("ember-bootstrap/templates/components/bs-form/group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HBwk1S/K",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[4,\"if\",[[23,[\"hasFeedback\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[12,\"class\",[28,[\"form-control-feedback \",[21,\"iconName\"]]]],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/group.hbs"
    }
  });

  _exports.default = _default;
});