define("ember-bootstrap/templates/components/bs-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "feWTInwx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"button\",\"toggle\",\"menu\",\"toggleDropdown\",\"openDropdown\",\"closeDropdown\",\"isOpen\"],[[27,\"component\",[[23,[\"buttonComponent\"]]],[[\"dropdown\",\"onClick\"],[[22,0,[]],[27,\"action\",[[22,0,[]],\"toggleDropdown\"],null]]]],[27,\"component\",[[23,[\"toggleComponent\"]]],[[\"dropdown\",\"inNav\",\"onClick\"],[[22,0,[]],[23,[\"inNav\"]],[27,\"action\",[[22,0,[]],\"toggleDropdown\"],null]]]],[27,\"component\",[[23,[\"menuComponent\"]]],[[\"isOpen\",\"direction\",\"inNav\",\"toggleElement\",\"dropdownElementId\"],[[23,[\"isOpen\"]],[23,[\"direction\"]],[23,[\"inNav\"]],[23,[\"toggleElement\"]],[23,[\"elementId\"]]]]],[27,\"action\",[[22,0,[]],\"toggleDropdown\"],null],[27,\"action\",[[22,0,[]],\"openDropdown\"],null],[27,\"action\",[[22,0,[]],\"closeDropdown\"],null],[23,[\"isOpen\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown.hbs"
    }
  });

  _exports.default = _default;
});