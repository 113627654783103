enifed("@ember/-internals/views/lib/system/utils", ["exports", "@ember/-internals/owner", "@ember/-internals/utils"], function (_exports, _owner, _utils) {
  "use strict";

  _exports.isSimpleClick = isSimpleClick;
  _exports.constructStyleDeprecationMessage = constructStyleDeprecationMessage;
  _exports.getRootViews = getRootViews;
  _exports.getViewId = getViewId;
  _exports.getViewElement = getViewElement;
  _exports.initViewElement = initViewElement;
  _exports.setViewElement = setViewElement;
  _exports.getChildViews = getChildViews;
  _exports.initChildViews = initChildViews;
  _exports.addChildView = addChildView;
  _exports.collectChildViews = collectChildViews;
  _exports.getViewBounds = getViewBounds;
  _exports.getViewRange = getViewRange;
  _exports.getViewClientRects = getViewClientRects;
  _exports.getViewBoundingClientRect = getViewBoundingClientRect;
  _exports.matches = matches;
  _exports.contains = contains;
  _exports.elMatches = void 0;

  /* globals Element */

  /**
  @module ember
  */
  function isSimpleClick(event) {
    let modifier = event.shiftKey || event.metaKey || event.altKey || event.ctrlKey;
    let secondaryClick = event.which > 1; // IE9 may return undefined

    return !modifier && !secondaryClick;
  }

  function constructStyleDeprecationMessage(affectedStyle) {
    return '' + 'Binding style attributes may introduce cross-site scripting vulnerabilities; ' + 'please ensure that values being bound are properly escaped. For more information, ' + 'including how to disable this warning, see ' + 'https://emberjs.com/deprecations/v1.x/#toc_binding-style-attributes. ' + 'Style affected: "' + affectedStyle + '"';
  }
  /**
    @private
    @method getRootViews
    @param {Object} owner
  */


  function getRootViews(owner) {
    let registry = owner.lookup('-view-registry:main');
    let rootViews = [];
    Object.keys(registry).forEach(id => {
      let view = registry[id];

      if (view.parentView === null) {
        rootViews.push(view);
      }
    });
    return rootViews;
  }
  /**
    @private
    @method getViewId
    @param {Ember.View} view
   */


  function getViewId(view) {
    if (view.tagName !== '' && view.elementId) {
      return view.elementId;
    } else {
      return (0, _utils.guidFor)(view);
    }
  }

  const VIEW_ELEMENT = (0, _utils.symbol)('VIEW_ELEMENT');
  /**
    @private
    @method getViewElement
    @param {Ember.View} view
   */

  function getViewElement(view) {
    return view[VIEW_ELEMENT];
  }

  function initViewElement(view) {
    view[VIEW_ELEMENT] = null;
  }

  function setViewElement(view, element) {
    return view[VIEW_ELEMENT] = element;
  }

  const CHILD_VIEW_IDS = new WeakMap();
  /**
    @private
    @method getChildViews
    @param {Ember.View} view
  */

  function getChildViews(view) {
    let owner = (0, _owner.getOwner)(view);
    let registry = owner.lookup('-view-registry:main');
    return collectChildViews(view, registry);
  }

  function initChildViews(view) {
    let childViews = new Set();
    CHILD_VIEW_IDS.set(view, childViews);
    return childViews;
  }

  function addChildView(parent, child) {
    let childViews = CHILD_VIEW_IDS.get(parent);

    if (childViews === undefined) {
      childViews = initChildViews(parent);
    }

    childViews.add(getViewId(child));
  }

  function collectChildViews(view, registry) {
    let views = [];
    let childViews = CHILD_VIEW_IDS.get(view);

    if (childViews !== undefined) {
      childViews.forEach(id => {
        let view = registry[id];

        if (view && !view.isDestroying && !view.isDestroyed) {
          views.push(view);
        }
      });
    }

    return views;
  }
  /**
    @private
    @method getViewBounds
    @param {Ember.View} view
  */


  function getViewBounds(view) {
    return view.renderer.getBounds(view);
  }
  /**
    @private
    @method getViewRange
    @param {Ember.View} view
  */


  function getViewRange(view) {
    let bounds = getViewBounds(view);
    let range = document.createRange();
    range.setStartBefore(bounds.firstNode);
    range.setEndAfter(bounds.lastNode);
    return range;
  }
  /**
    `getViewClientRects` provides information about the position of the border
    box edges of a view relative to the viewport.
  
    It is only intended to be used by development tools like the Ember Inspector
    and may not work on older browsers.
  
    @private
    @method getViewClientRects
    @param {Ember.View} view
  */


  function getViewClientRects(view) {
    let range = getViewRange(view);
    return range.getClientRects();
  }
  /**
    `getViewBoundingClientRect` provides information about the position of the
    bounding border box edges of a view relative to the viewport.
  
    It is only intended to be used by development tools like the Ember Inspector
    and may not work on older browsers.
  
    @private
    @method getViewBoundingClientRect
    @param {Ember.View} view
  */


  function getViewBoundingClientRect(view) {
    let range = getViewRange(view);
    return range.getBoundingClientRect();
  }
  /**
    Determines if the element matches the specified selector.
  
    @private
    @method matches
    @param {DOMElement} el
    @param {String} selector
  */


  const elMatches = typeof Element !== 'undefined' && (Element.prototype.matches || Element.prototype.matchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector || Element.prototype.oMatchesSelector || Element.prototype.webkitMatchesSelector);
  _exports.elMatches = elMatches;

  function matches(el, selector) {
    return elMatches.call(el, selector);
  }

  function contains(a, b) {
    if (a.contains !== undefined) {
      return a.contains(b);
    }

    while (b = b.parentNode) {
      if (b === a) {
        return true;
      }
    }

    return false;
  }
});