define('ember-concurrency/-task-group', ['exports', 'ember-concurrency/utils', 'ember-concurrency/-task-state-mixin', 'ember-concurrency/-property-modifiers-mixin'], function (exports, _utils, _taskStateMixin, _propertyModifiersMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.TaskGroupProperty = exports.TaskGroup = undefined;
  const TaskGroup = exports.TaskGroup = Ember.Object.extend(_taskStateMixin.default, {
    isTaskGroup: true,

    toString() {
      return `<TaskGroup:${this._propertyName}>`;
    },

    _numRunningOrNumQueued: Ember.computed.or('numRunning', 'numQueued'),
    isRunning: Ember.computed.bool('_numRunningOrNumQueued'),
    isQueued: false
  });

  class TaskGroupProperty extends _utils._ComputedProperty {
    constructor(taskFn) {
      let tp;
      super(function (_propertyName) {
        return TaskGroup.create({
          fn: taskFn,
          context: this,
          _origin: this,
          _taskGroupPath: tp._taskGroupPath,
          _scheduler: (0, _propertyModifiersMixin.resolveScheduler)(tp, this, TaskGroup),
          _propertyName
        });
      });
      tp = this;
    }
  }

  exports.TaskGroupProperty = TaskGroupProperty;
  (0, _utils.objectAssign)(TaskGroupProperty.prototype, _propertyModifiersMixin.propertyModifiers);
});