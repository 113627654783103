define('ember-light-table/components/lt-column-resizer', ['exports', 'ember-light-table/templates/components/lt-column-resizer'], function (exports, _ltColumnResizer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const TOP_LEVEL_CLASS = '.ember-light-table';

  exports.default = Ember.Component.extend({
    layout: _ltColumnResizer.default,
    classNameBindings: [':lt-column-resizer', 'isResizing'],
    column: null,
    resizeOnDrag: false,

    isResizing: false,
    startWidth: null,
    startX: null,

    $column: Ember.computed(function () {
      return Ember.$(this.get('element')).parent('th');
    }).volatile().readOnly(),

    didInsertElement() {
      this._super(...arguments);

      this.__mouseMove = this._mouseMove.bind(this);
      this.__mouseUp = this._mouseUp.bind(this);

      Ember.$(document).on('mousemove', this.__mouseMove);
      Ember.$(document).on('mouseup', this.__mouseUp);
    },

    willDestroyElement() {
      this._super(...arguments);

      Ember.$(document).off('mousemove', this.__mouseMove);
      Ember.$(document).off('mouseup', this.__mouseUp);
    },

    click(e) {
      /*
        Prevent click events from propagating (i.e. onColumnClick)
       */
      e.preventDefault();
      e.stopPropagation();
    },

    mouseDown(e) {
      let $column = this.get('$column');

      e.preventDefault();
      e.stopPropagation();

      this.setProperties({
        isResizing: true,
        startWidth: $column.outerWidth(),
        startX: e.pageX
      });

      this.$().closest(TOP_LEVEL_CLASS).addClass('is-resizing');
    },

    _mouseUp(e) {
      if (this.get('isResizing')) {
        e.preventDefault();
        e.stopPropagation();

        let $column = this.get('$column');
        let width = `${$column.outerWidth()}px`;

        this.set('isResizing', false);
        this.set('column.width', width);

        this.sendAction('onColumnResized', width);
        this.$().closest(TOP_LEVEL_CLASS).removeClass('is-resizing');
      }
    },

    _mouseMove(e) {
      if (this.get('isResizing')) {
        e.preventDefault();
        e.stopPropagation();

        let resizeOnDrag = this.get('resizeOnDrag');
        let minResizeWidth = this.get('column.minResizeWidth');
        let { startX, startWidth } = this.getProperties(['startX', 'startWidth']);
        let width = `${Math.max(startWidth + (e.pageX - startX), minResizeWidth)}px`;

        let $column = this.get('$column');
        let $index = this.get('table.visibleColumns').indexOf(this.get('column')) + 1;
        let $table = this.$().closest(TOP_LEVEL_CLASS);

        $column.outerWidth(width);
        Ember.$(`thead td.lt-scaffolding:nth-child(${$index})`, $table).outerWidth(width);
        Ember.$(`tfoot td.lt-scaffolding:nth-child(${$index})`, $table).outerWidth(width);

        if (resizeOnDrag) {
          Ember.$(`tbody td:nth-child(${$index})`, $table).outerWidth(width);
        }
      }
    }
  });
});