define('ember-light-table/components/lt-infinity', ['exports', 'ember-light-table/templates/components/lt-infinity', 'ember-in-viewport'], function (exports, _ltInfinity, _emberInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberInViewport.default, {
    classNames: ['lt-infinity'],
    classNameBindings: ['viewportEntered:in-viewport'],
    layout: _ltInfinity.default,

    rows: null,
    scrollableContent: null,
    scrollBuffer: 50,

    didInsertElement() {
      this._super(...arguments);

      let scrollBuffer = this.get('scrollBuffer');
      let width = this.$().width();
      let scrollableContent = this.get('scrollableContent');

      this.setProperties({
        viewportSpy: true,
        viewportTolerance: {
          left: width,
          right: width,
          bottom: scrollBuffer,
          top: 0
        },
        scrollableArea: scrollableContent
      });
    },

    didEnterViewport() {
      Ember.get(this, 'inViewport')();
    },

    didExitViewport() {
      Ember.get(this, 'exitViewport')();
    }
  });
});