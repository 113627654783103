define('ember-light-table/components/cells/base', ['exports', 'ember-light-table/templates/components/cells/base', 'ember-light-table/utils/css-styleify'], function (exports, _base, _cssStyleify) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * @module Light Table
   * @submodule Cell Types
   */

  /**
   * @module Cell Types
   * @class Base Cell
   */

  const Cell = Ember.Component.extend({
    layout: _base.default,
    tagName: 'td',
    classNames: ['lt-cell'],
    attributeBindings: ['style'],
    classNameBindings: ['align', 'isSorted', 'column.cellClassNames'],

    enableScaffolding: false,

    isSorted: Ember.computed.readOnly('column.sorted'),

    style: Ember.computed('enableScaffolding', 'column.width', function () {
      let column = this.get('column');

      if (this.get('enableScaffolding') || !column) {
        return '';
      }

      return (0, _cssStyleify.default)(column.getProperties(['width']));
    }),

    align: Ember.computed('column.align', function () {
      return `align-${this.get('column.align')}`;
    }),

    /**
     * @property table
     * @type {Table}
     */
    table: null,

    /**
     * @property column
     * @type {Column}
     */
    column: null,

    /**
     * @property row
     * @type {Row}
     */
    row: null,

    /**
     * @property tableActions
     * @type {Object}
     */
    tableActions: null,

    /**
     * @property extra
     * @type {Object}
     */
    extra: null,

    /**
     * @property rawValue
     * @type {Mixed}
     */
    rawValue: null,

    /**
     * @property value
     * @type {Mixed}
     */
    value: Ember.computed('rawValue', function () {
      let rawValue = this.get('rawValue');
      let format = this.get('column.format');

      if (format && typeof format === 'function') {
        return format.call(this, rawValue);
      }
      return rawValue;
    })
  });

  Cell.reopenClass({
    positionalParams: ['column', 'row']
  });

  exports.default = Cell;
});