enifed("@ember/deprecated-features/index", ["exports"], function (_exports) {
  "use strict";

  _exports.COMPONENT_MANAGER_STRING_LOOKUP = _exports.TRANSITION_STATE = _exports.ROUTER_EVENTS = _exports.HANDLER_INFOS = _exports.MERGE = _exports.LOGGER = _exports.RUN_SYNC = _exports.EMBER_EXTEND_PROTOTYPES = _exports.SEND_ACTION = void 0;

  /* eslint-disable no-implicit-coercion */
  const SEND_ACTION = !!'3.4.0';
  _exports.SEND_ACTION = SEND_ACTION;
  const EMBER_EXTEND_PROTOTYPES = !!'3.2.0-beta.5';
  _exports.EMBER_EXTEND_PROTOTYPES = EMBER_EXTEND_PROTOTYPES;
  const RUN_SYNC = !!'3.0.0-beta.4';
  _exports.RUN_SYNC = RUN_SYNC;
  const LOGGER = !!'3.2.0-beta.1';
  _exports.LOGGER = LOGGER;
  const MERGE = !!'3.6.0-beta.1';
  _exports.MERGE = MERGE;
  const HANDLER_INFOS = !!'3.9.0';
  _exports.HANDLER_INFOS = HANDLER_INFOS;
  const ROUTER_EVENTS = !!'3.9.0';
  _exports.ROUTER_EVENTS = ROUTER_EVENTS;
  const TRANSITION_STATE = !!'3.9.0';
  _exports.TRANSITION_STATE = TRANSITION_STATE;
  const COMPONENT_MANAGER_STRING_LOOKUP = !!'4.0.0';
  _exports.COMPONENT_MANAGER_STRING_LOOKUP = COMPONENT_MANAGER_STRING_LOOKUP;
});