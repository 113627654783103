enifed("@ember/debug/lib/handlers", ["exports"], function (_exports) {
  "use strict";

  _exports.invoke = _exports.registerHandler = _exports.HANDLERS = void 0;
  let HANDLERS = {};
  _exports.HANDLERS = HANDLERS;

  let registerHandler = () => {};

  _exports.registerHandler = registerHandler;

  let invoke = () => {};

  _exports.invoke = invoke;

  if (true
  /* DEBUG */
  ) {
      _exports.registerHandler = registerHandler = function registerHandler(type, callback) {
        let nextHandler = HANDLERS[type] || (() => {});

        HANDLERS[type] = (message, options) => {
          callback(message, options, nextHandler);
        };
      };

      _exports.invoke = invoke = function invoke(type, message, test, options) {
        if (test) {
          return;
        }

        let handlerForType = HANDLERS[type];

        if (handlerForType) {
          handlerForType(message, options);
        }
      };
    }
});