define('ember-pikaday/components/pikaday-inputless', ['exports', 'ember-pikaday/mixins/pikaday', 'ember-pikaday/templates/pikaday-inputless'], function (exports, _pikaday, _pikadayInputless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_pikaday.default, {
    layout: _pikadayInputless.default,

    didInsertElement() {
      this.set('field', this.$('.ember-pikaday-input')[0]);
      this.set('pikadayContainer', this.$('.ember-pikaday-container')[0]);
      this.setupPikaday();
    },

    onPikadayOpen() {},
    onPikadayClose() {}
  });
});