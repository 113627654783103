define('ember-light-table/components/columns/base', ['exports', 'ember-light-table/templates/components/columns/base', 'ember-light-table/mixins/draggable-column', 'ember-light-table/utils/css-styleify'], function (exports, _base, _draggableColumn, _cssStyleify) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * @module Light Table
   * @submodule Column Types
   */

  /**
   * @module Column Types
   * @class Base Column
   */

  const Column = Ember.Component.extend(_draggableColumn.default, {
    layout: _base.default,
    tagName: 'th',
    classNames: ['lt-column'],
    attributeBindings: ['style', 'colspan', 'rowspan'],
    classNameBindings: ['align', 'isGroupColumn:lt-group-column', 'isHideable', 'isSortable', 'isSorted', 'isResizable', 'isResizing', 'isDraggable', 'column.classNames'],

    isGroupColumn: Ember.computed.readOnly('column.isGroupColumn'),
    isSortable: Ember.computed.readOnly('column.sortable'),
    isSorted: Ember.computed.readOnly('column.sorted'),
    isHideable: Ember.computed.readOnly('column.hideable'),
    isResizable: Ember.computed.readOnly('column.resizable'),
    isDraggable: Ember.computed.readOnly('column.draggable'),
    isResizing: false,

    style: Ember.computed('column.width', function () {
      return (0, _cssStyleify.default)(this.get('column').getProperties(['width']));
    }),

    align: Ember.computed('column.align', function () {
      return `align-${this.get('column.align')}`;
    }),

    /**
     * @property label
     * @type {String}
     */
    label: Ember.computed.oneWay('column.label'),

    /**
     * @property table
     * @type {Table}
     */
    table: null,

    /**
     * @property column
     * @type {Column}
     */
    column: null,

    /**
     * @property tableActions
     * @type {Object}
     */
    tableActions: null,

    /**
     * @property extra
     * @type {Object}
     */
    extra: null,

    /**
     * @property sortIcons
     * @type {Object}
     */
    sortIcons: null,

    /**
     * @property sortIconProperty
     * @type {String|null}
     * @private
     */
    sortIconProperty: Ember.computed('column.{sortable,sorted,ascending}', function () {
      let sorted = this.get('column.sorted');

      if (sorted) {
        let ascending = this.get('column.ascending');
        return ascending ? 'iconAscending' : 'iconDescending';
      }

      let sortable = this.get('column.sortable');
      return sortable ? 'iconSortable' : null;
    }),

    /**
     * @property colspan
     * @type {Number}
     */
    colspan: Ember.computed('column', 'column.visibleSubColumns.[]', function () {
      let subColumns = this.get('column.visibleSubColumns');
      return !Ember.isEmpty(subColumns) ? subColumns.length : 1;
    }),

    /**
     * @property rowspan
     * @type {Number}
     */
    rowspan: Ember.computed('column.visibleSubColumns.[]', function () {
      let subColumns = this.get('column.visibleSubColumns');
      return !Ember.isEmpty(subColumns) ? 1 : 2;
    })
  });

  Column.reopenClass({
    positionalParams: ['column']
  });

  exports.default = Column;
});