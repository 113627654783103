define('ember-light-table/-private/sync-array-proxy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const EMPTY_ARRAY = [];

  exports.default = Ember.ArrayProxy.extend({
    /**
     * The model that will be synchronized to the content of this proxy
     * @property syncArray
     * @type {Array}
     */
    syncArray: null,

    /**
     * @property syncEnabled
     * @type {Boolean}
     */
    syncEnabled: true,

    init() {
      this._super(...arguments);

      let syncArray = this.get('syncArray');

      (true && !(Ember.isArray(syncArray) && typeof syncArray.addArrayObserver === 'function') && Ember.assert('[ember-light-table] enableSync requires the passed array to be an instance of Ember.A', Ember.isArray(syncArray) && typeof syncArray.addArrayObserver === 'function'));


      syncArray.addArrayObserver(this, {
        willChange: 'syncArrayWillChange',
        didChange: 'syncArrayDidChange'
      });
    },

    destroy() {
      this.get('syncArray').removeArrayObserver(this, {
        willChange: 'syncArrayWillChange',
        didChange: 'syncArrayDidChange'
      });

      this.setProperties({
        syncArray: null,
        content: null
      });
    },

    /**
     * Serialize objects before they are inserted into the content array
     * @method serializeContentObjects
     * @param {Array} objects
     * @return {Array}
     */
    serializeContentObjects(objects) {
      return objects;
    },

    /**
     * Serialize objects before they are inserted into the sync array
     * @method serializeSyncArrayObjects
     * @param {Array} objects
     * @return {Array}
     */
    serializeSyncArrayObjects(objects) {
      return objects;
    },

    syncArrayWillChange() {/* Not needed */},

    syncArrayDidChange(syncArray, start, removeCount, addCount) {
      let content = this.get('content');
      let objectsToAdd = EMPTY_ARRAY;

      if (!this.get('syncEnabled')) {
        return;
      }

      if (addCount > 0) {
        objectsToAdd = this.serializeContentObjects(syncArray.slice(start, start + addCount));
      }

      content.replace(start, removeCount, objectsToAdd);
    },

    replaceContent(start, removeCount, objectsToAdd) {
      let syncArray = this.get('syncArray');

      if (!this.get('syncEnabled')) {
        return this._super(...arguments);
      }

      syncArray.replace(start, removeCount, this.serializeSyncArrayObjects(objectsToAdd));
    }
  });
});