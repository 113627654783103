define('ember-light-table/utils/css-styleify', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = cssStyleify;
  function cssStyleify(hash = {}) {
    let styles = [];

    Object.keys(hash).forEach(key => {
      let value = hash[key];

      if (Ember.isPresent(value)) {
        styles.push(`${Ember.String.dasherize(key)}: ${value}`);
      }
    });

    return Ember.String.htmlSafe(styles.join('; '));
  }
});