define("ember-bootstrap/templates/components/bs-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NnIRC4I5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"element\",\"group\",\"isSubmitting\",\"isSubmitted\",\"isRejected\",\"resetSubmissionState\",\"submit\"],[[27,\"component\",[[23,[\"elementComponent\"]]],[[\"model\",\"formLayout\",\"horizontalLabelGridClass\",\"showAllValidations\",\"disabled\",\"readonly\",\"onChange\",\"_onChange\"],[[23,[\"model\"]],[23,[\"formLayout\"]],[23,[\"horizontalLabelGridClass\"]],[23,[\"showAllValidations\"]],[22,0,[\"disabled\"]],[22,0,[\"readonly\"]],[27,\"action\",[[22,0,[]],\"change\"],null],[27,\"action\",[[22,0,[]],\"resetSubmissionState\"],null]]]],[27,\"component\",[[23,[\"groupComponent\"]]],[[\"formLayout\"],[[23,[\"formLayout\"]]]]],[23,[\"isSubmitting\"]],[23,[\"isSubmitted\"]],[23,[\"isRejected\"]],[27,\"action\",[[22,0,[]],\"resetSubmissionState\"],null],[27,\"action\",[[22,0,[]],\"submit\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form.hbs"
    }
  });

  _exports.default = _default;
});