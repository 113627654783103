define('ember-light-table/-private/global-options', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mergeOptionsWithGlobals = mergeOptionsWithGlobals;


  // eslint-disable-next-line ember-suave/no-direct-property-access
  const globalOptions = _emberGetConfig.default['ember-light-table'] || {};

  exports.default = globalOptions;
  function mergeOptionsWithGlobals(options) {
    return Ember.merge(Ember.merge({}, globalOptions), options);
  }
});