define("ember-key-manager/utils/modifier-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TO_KEY = _exports.TO_MODIFIER = _exports.SHIFT_KEY = _exports.META_KEY = _exports.CTRL_KEY = _exports.ALT_KEY = _exports.SHIFT_MODIFIER = _exports.META_MODIFIER = _exports.CTRL_MODIFIER = _exports.ALT_MODIFIER = void 0;
  const ALT_MODIFIER = 'altKey';
  _exports.ALT_MODIFIER = ALT_MODIFIER;
  const CTRL_MODIFIER = 'ctrlKey';
  _exports.CTRL_MODIFIER = CTRL_MODIFIER;
  const META_MODIFIER = 'metaKey';
  _exports.META_MODIFIER = META_MODIFIER;
  const SHIFT_MODIFIER = 'shiftKey';
  _exports.SHIFT_MODIFIER = SHIFT_MODIFIER;
  const ALT_KEY = 'Alt';
  _exports.ALT_KEY = ALT_KEY;
  const CTRL_KEY = 'Control';
  _exports.CTRL_KEY = CTRL_KEY;
  const META_KEY = 'Meta';
  _exports.META_KEY = META_KEY;
  const SHIFT_KEY = 'Shift';
  _exports.SHIFT_KEY = SHIFT_KEY;
  const TO_MODIFIER = {
    [ALT_KEY]: ALT_MODIFIER,
    [CTRL_KEY]: CTRL_MODIFIER,
    [META_KEY]: META_MODIFIER,
    [SHIFT_KEY]: SHIFT_MODIFIER
  };
  _exports.TO_MODIFIER = TO_MODIFIER;
  const TO_KEY = {
    [ALT_MODIFIER]: ALT_KEY,
    [CTRL_MODIFIER]: CTRL_KEY,
    [META_MODIFIER]: META_KEY,
    [SHIFT_MODIFIER]: SHIFT_KEY
  };
  _exports.TO_KEY = TO_KEY;
});