define("ember-key-manager/services/key-manager", ["exports", "ember-key-manager/utils/macro", "ember-key-manager/utils/modifier-keys", "ember-key-manager/utils/warning-messages"], function (_exports, _macro, _modifierKeys, _warningMessages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const inputElements = ['INPUT', 'SELECT', 'TEXTAREA'];

  const isInputElement = element => {
    const isContentEditable = element.isContentEditable;
    const isInput = inputElements.includes(element.tagName);
    return isContentEditable || isInput;
  };

  var _default = Ember.Service.extend({
    isDisabledOnInput: false,
    // Config option
    keydownMacros: Ember.computed.filterBy('macros', 'keyEvent', 'keydown'),
    keyupMacros: Ember.computed.filterBy('macros', 'keyEvent', 'keyup'),

    init() {
      this._super(...arguments);

      this.macros = Ember.A();

      this._registerConfigOptions();
    },

    addMacro(options) {
      const macroAttrs = this._mergeConfigDefaults(options);

      const macro = _macro.default.create();

      macro.setup(macroAttrs);
      const keyEvent = Ember.get(macro, 'keyEvent');

      this._handleModifiersOnKeyup(macro, keyEvent);

      const element = Ember.get(macro, 'element');

      this._addEventListener(element, keyEvent);

      const macros = Ember.get(this, 'macros');
      macros.pushObject(macro);
      return macro;
    },

    _handleModifiersOnKeyup({
      modifierKeys
    }, keyEvent) {
      if (keyEvent === 'keyup' && Ember.isPresent(modifierKeys)) {
        (true && Ember.warn(_warningMessages.MODIFIERS_ON_KEYUP, false, {
          id: 'keyup-with-modifiers'
        }));
      }
    },

    _mergeConfigDefaults(attrs) {
      const isDisabledOnInput = Ember.get(this, 'isDisabledOnInput');
      return Ember.assign({
        isDisabledOnInput
      }, attrs);
    },

    _addEventListener(element, keyEvent) {
      const hasListenerForElementAndKeyEvent = this._findMacroWithElementAndKeyEvent(element, keyEvent);

      if (!hasListenerForElementAndKeyEvent) {
        element.addEventListener(keyEvent, this);
      }
    },

    removeMacro(macro) {
      const element = Ember.get(macro, 'element');
      const keyEvent = Ember.get(macro, 'keyEvent');
      const macros = Ember.get(this, 'macros');
      macros.removeObject(macro);

      this._removeEventListenter(element, keyEvent);
    },

    _removeEventListenter(element, keyEvent) {
      const hasListenerForElementAndKeyEvent = this._findMacroWithElementAndKeyEvent(element, keyEvent);

      if (!hasListenerForElementAndKeyEvent) {
        element.removeEventListener(keyEvent, this);
      }
    },

    disable(recipient) {
      this._setDisabledState(recipient, true);
    },

    enable(recipient) {
      this._setDisabledState(recipient, false);
    },

    handleEvent(event) {
      if (Ember.get(this, 'isDestroyed') || Ember.get(this, 'isDestroying')) {
        return false;
      }

      const isKeydown = event.type === 'keydown';
      const isKeyup = event.type === 'keyup';

      if (isKeydown || isKeyup) {
        const allEventModifierKeys = {
          altKey: event.altKey,
          ctrlKey: event.ctrlKey,
          metaKey: event.metaKey,
          shiftKey: event.shiftKey
        };
        const eventModifierKeys = Ember.A(Object.keys(allEventModifierKeys).filter(key => {
          return allEventModifierKeys[key] !== false;
        }));

        const matchingMacros = this._findMatchingMacros(event.target, event.key || '', eventModifierKeys, event.type);

        if (Ember.isPresent(matchingMacros)) {
          const isTargetInput = isInputElement(event.target);
          event.stopPropagation();
          matchingMacros.forEach(matchingMacro => {
            const isDisabled = Ember.get(matchingMacro, 'isDisabled') || Ember.get(matchingMacro, 'isDisabledOnInput') && isTargetInput;

            if (!isDisabled) {
              Ember.get(matchingMacro, 'callback')(event);
            }
          });
        }
      }
    },

    _findMacroWithElementAndKeyEvent(eventElement, eventKeyEvent) {
      return Ember.get(this, `${eventKeyEvent}Macros`).find(macro => {
        const element = Ember.get(macro, 'element');
        return eventElement === element;
      });
    },

    _findMatchingMacros(eventElement, eventExecutionKey, eventModifierKeys, eventKeyEvent) {
      const matchingMacros = Ember.get(this, `${eventKeyEvent}Macros`).filter(macro => {
        const {
          element,
          executionKey,
          modifierKeys
        } = Ember.getProperties(macro, ['element', 'executionKey', 'modifierKeys']);
        const hasElementMatch = element === eventElement || element.contains(eventElement);
        const hasExecutionKeyMatch = eventExecutionKey.toLowerCase() === executionKey.toLowerCase();
        const hasModifierKeysMatch = eventModifierKeys.removeObject(_modifierKeys.TO_MODIFIER[eventExecutionKey]).every(key => {
          return modifierKeys.toArray().map(k => Ember.String.capitalize(k)).includes(_modifierKeys.TO_KEY[key]);
        });
        const hasModifierKeyCount = eventModifierKeys.length === modifierKeys.length;
        return hasElementMatch && hasExecutionKeyMatch && hasModifierKeysMatch && hasModifierKeyCount;
      });
      const highestPriority = Ember.A(matchingMacros).mapBy('priority').reduce((max, priority) => Math.max(max, priority), -Infinity);
      return matchingMacros.filter(macro => Ember.get(macro, 'priority') === highestPriority);
    },

    _registerConfigOptions() {
      const config = Ember.getOwner(this).lookup('main:key-manager-config');

      if (config) {
        Ember.setProperties(this, config);
      }
    },

    _setDisabledState(recipient, isDisabled) {
      if (typeof recipient === 'string') {
        this._setGroupDisabledState(recipient, isDisabled);
      } else {
        Ember.set(recipient, 'isDisabled', isDisabled);
      }
    },

    _setGroupDisabledState(groupName, isDisabled) {
      Ember.get(this, 'macros').filterBy('groupName', groupName).setEach('isDisabled', isDisabled);
    }

  });

  _exports.default = _default;
});